<template>
  <div v-show="!item.destroy" class="form-group row">
    <div class="col-sm-3">
      <input v-if="item.id" type="hidden" name="product[bundle_items_attributes][][id]" :value="item.id">
      <input type="hidden" name="product[bundle_items_attributes][][child_id]" :value="item.product.id">
      <input type="hidden" name="product[bundle_items_attributes][][kind]" value="bundle">
      <input v-if="item.destroy" type="hidden" name="product[bundle_items_attributes][][_destroy]" value="true">
      <input
        type="number"
        :id="`count_${item.id}`"
        :class="['form-control', { 'is-invalid': errorsOutput.length }]"
        name="product[bundle_items_attributes][][count]"
        :value="item.count"
        step="1"
        @input="onInput">
    </div>
    <label for="inputPassword" class="col-sm-8 col-form-label">{{ item.product.title }}</label>
    <div class="col-sm-1 form-control-plaintext">
      <a href="#" @click.prevent="onDelete">
        <i class="fas fa-trash-alt" />
      </a>
    </div>
    <small v-if="errorsOutput.length" class="form-text text-danger">{{ errorsOutput }}</small>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    errors: {
      type: Object
    }
  },
  computed: {
    errorsOutput() {
      if (this.errors?.[`bundleItems[${this.index}]Count`]) return this.errors[`bundleItems[${this.index}]Count`].join(', ')
      return ''
    }
  },
  methods: {
    onInput(e) {
      const count = parseInt(e.target.value, 10)
      this.$emit('onItemCountChanged', { item: this.item, count })
    },
    onDelete() {
      this.$emit('onItemDelete', this.item)
    }
  }
}
</script>
