<template>
  <div v-show="!item.destroy" class="form-group row">
    <input v-if="item.id" type="hidden" name="product[addon_items_attributes][][id]" :value="item.id">
    <input type="hidden" name="product[addon_items_attributes][][child_id]" :value="item.addon.id">
    <input type="hidden" name="product[addon_items_attributes][][kind]" value="addon">
    <input v-if="item.destroy" type="hidden" name="product[addon_items_attributes][][_destroy]" value="true">
    <input type="hidden" :id="`count_${item.id}`" name="product[addon_items_attributes][][count]" :value="item.count">
    <label for="inputPassword" class="col-sm-11 col-form-label">{{ item.addon.title }}</label>
    <div class="col-sm-1 form-control-plaintext">
      <a href="#" @click.prevent="onDelete">
        <i class="fas fa-trash-alt" />
      </a>
    </div>
    <small v-if="errorsOutput.length" class="form-text text-danger">{{ errorsOutput }}</small>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    errors: {
      type: Object
    }
  },
  computed: {
    errorsOutput() {
      if (this.errors?.[`bundleItems[${this.index}]Count`]) return this.errors[`bundleItems[${this.index}]Count`].join(', ')
      return ''
    }
  },
  methods: {
    onDelete() {
      this.$emit('onItemDelete', this.item)
    }
  }
}
</script>
