<template>
  <ClipLoader v-if="isLoading" />

  <div v-else class="mt-4">
    <div class="card card-body border-light shadow-sm mb-3">
      <h3>Info</h3>
      <dl class="row mb-0">
        <dt class="col-sm-2">Total Price</dt>
        <dd class="col-sm-9">{{ totalPrice | formatPriceCents }}</dd>

        <dt class="col-sm-2">Paid</dt>
        <dd class="col-sm-9">{{ stripeCharge.status === 'succeeded' ? 'Yes' : 'No' }}</dd>
      </dl>

      <hr>
      <h3>Items</h3>
      <OrderItem
        v-for="id in items.allIds"
        :key="id"
        :item="items.byId[id]"
        :product="products.byId[items.byId[id].purchaseableId]"
        :client="clients.byId[items.byId[id].clientId]"
        :comments="comments"
        :products="products"
        :wsfs="wsfs"
        :wss="wss"
        :order-id="order.id"
        @addComment='addComment'
      />
    </div>
  </div>
</template>

<script>
import normalizeData from 'javascripts/common/normalize_data'

import camelCaseKeys from 'camelcase-keys'
import camelCaseKeysDeep from 'camelcase-keys-deep'
import OrderItem from './show/order_item'

function transformResponse(data, _headers) {
  const json = JSON.parse(data)
  return {
    ...camelCaseKeysDeep(json),
    items: json.items.map((item) => camelCaseKeys(item))
  }
}

export default {
  components: { OrderItem },
  data() {
    return {
      isLoading: true,
      order: {},
      items: { byId: {}, allIds: []},
      clients: { byId: {}, allIds: []},
      comments: { byId: {}, allIds: []},
      products: { byId: {}, allIds: []},
      wsfs: { byId: {}, allIds: []},
      wss: { byId: {}, allIds: []},
      stripeCharge: {}
    }
  },
  computed: {
    totalPrice() {
      return this.items.allIds.reduce((memo, id) => {
        const item = this.items.byId[id]
        const addonsCost = Object.keys(item.selectedAddons).reduce((m, addId) => m + this.products.byId[addId].priceCents * item.selectedAddons[addId], 0)
        return memo + this.products.byId[item.purchaseableId].priceCents * item.count + addonsCost
      }, 0)
    }
  },
  created() {
    axios.get(`/ajax${window.location.pathname}`, { transformResponse })
      .then((res) => {
        this.isLoading = false

        this.order = res.data.order

        this.clients = normalizeData(res.data.clients)
        const itemComments = {}
        this.comments = normalizeData(res.data.comments, {
          callback({ id, orderItemId }) {
            itemComments[orderItemId] = itemComments[orderItemId] || []
            itemComments[orderItemId].push(id)
          }
        })
        this.products = normalizeData(res.data.products)
        this.wsfs = normalizeData(res.data.wsfs)
        this.wss = normalizeData(res.data.wss)

        this.items = normalizeData(res.data.items, {
          elMutation(el) {
            return { ...el, commentIds: itemComments[el.id] || []}
          }
        })

        const { charge } = res.data.stripe
        this.stripeCharge = charge || {}
      })
  },
  methods: {
    addComment({ comment, item }) {
      this.comments.allIds.push(comment.id)
      this.comments.byId[comment.id] = comment
      item.commentIds.push(comment.id)
    }
  }
}
</script>
