<template>
  <div class="form-group">
    <label class="form-check-label d-block">{{ label }}</label>
    <div class="form-check d-inline-block mr-3" v-for="option in options" :key="option.value">
      <input
        type="radio"
        :class="['form-check-input', { 'is-invalid': hasErrors }]"
        :id="`${id}_${option.value}`"
        :name="name"
        :value="option.value"
        :checked="value === option.value"
        @input="onInput(option.value)">
      <label class="form-check-label" :for="`${id}_${option.value}`">{{ option.label }}</label>
    </div>
    <small v-if="hasErrors" class="form-text text-danger">{{ errors.join(', ') }}</small>
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import fieldMixin from './field_mixin'

export default {
  mixins: [fieldMixin],
  props: {
    value: {
      type: String,
      required: true
    },
    options: {
      required: true,
      type: Array
    }
  }
}
</script>
