<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :class="['form-control', { 'is-invalid': hasErrors }]"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :rows="rows"
      @input="onInput($event.target.value)" />
    <small v-if="hasErrors" class="form-text text-danger">{{ errors.join(', ') }}</small>
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import fieldMixin from './field_mixin'

export default {
  mixins: [fieldMixin],
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null
    },
    placeholder: {
      type: String
    },
    rows: {
      type: [String, Number],
      default: 5
    }
  }
}
</script>
