<template>
  <div class="mt-5 col-md-6">
    <ClipLoader v-if="isLoading" />

    <form v-else action="/" @submit.prevent="onSubmit" ref="form">
      <fieldset :disabled="isSubmiting">
        <FormRadioGroup v-model="product.kind"
                        v-show="!product.id"
                        label="Kind"
                        id="kind"
                        name="product[kind]"
                        :options="kinds"
                        :errors="errors.kind" />

        <Items
          v-if="product.kind === 'bundle'"
          :items="product.items"
          :errors="errors"
          query-url="/ajax/admin/products?kind=product"
          @onOptionSelected="onBundleOptionSelected">
          <template #header>Bundle Items</template>

          <template #item="{ item, index }">
            <BundleItem
              :item="item"
              :index="index"
              :errors="errors"
              @onItemCountChanged="onBundleItemCountChanged"
              @onItemDelete="onItemDelete('items', $event)" />
          </template>
        </Items>

        <Items
          v-if="product.kind !== 'addon'"
          :items="product.addons || []"
          :errors="errors"
          query-url="/ajax/admin/products?kind=addon"
          @onOptionSelected="onAddonOptionSelected">
          <template #header>Addons</template>
          <template #item="{ item, index }">
            <AddonItem
              :item="item"
              :index="index"
              :errors="errors"
              @onItemDelete="onItemDelete('addons', $event)" />
          </template>
        </Items>

        <FormInput v-model="product.title"
                   label="Title"
                   id="title"
                   name="product[title]"
                   placeholder="Title of the product"
                   :errors="errors.title" />

        <FormTextarea v-model="product.description"
                      label="Description"
                      id="description"
                      name="product[description]"
                      placeholder="Description of the product"
                      :errors="errors.description" />

        <FormInput v-model="price"
                   type="number"
                   name="product[price_cents]"
                   step="0.01"
                   label="Price"
                   id="price"
                   placeholder="Enter price"
                   :errors="errors.priceCents" />

        <FormInput v-model="product.videoUrl"
                   label="URL for Promo video"
                   id="videoUrl"
                   name="product[video_url]"
                   placeholder="Video URL"
                   :errors="errors.videoUrl" />

        <FormFile v-model="product.image"
                  label="Cover image"
                  id="image"
                  name="product[image]"
                  :errors="errors.image" />

        <FormCheckbox v-model="product.purchasable"
                      label="Purchasable"
                      name="product[purchasable]"
                      id="purchasable" />

        <FormCheckbox v-model="product.recurring"
                      label="Recurring"
                      name="product[recurring]"
                      id="recurring" />

        <FormSelectAsync v-model="product.worksheetForm"
                         url="/ajax/admin/worksheet_forms"
                         label="Worksheet Form"
                         id="worksheetForm"
                         :reformat-options="reformatWorksheetsOptions" />

        <FormSelectAsync v-model="product.trelloList"
                         url="/ajax/admin/trello_lists"
                         label="Trello List"
                         id="trelloList"
                         :reformat-options="reformatTrelloListOptions" />

        <FormSubmit :is-submiting="isSubmiting" back-link="/admin/products" />
      </fieldset>
    </form>
  </div>
</template>

<script>
import {
  FormInput, FormTextarea, FormCheckbox, FormFile, FormSubmit, FormSelectAsync, FormRadioGroup
} from '../../../components/form'

import Items from './form/bundle_addon_items'
import BundleItem from './form/bundle_item'
import AddonItem from './form/addon_item'

export default {
  components: {
    FormInput, FormTextarea, FormCheckbox, FormFile, FormSubmit, FormSelectAsync, FormRadioGroup, Items, BundleItem, AddonItem
  },
  data() {
    return {
      isLoading: true,
      isSubmiting: false,
      errors: {},
      product: {
        kind: 'product',
        title: '',
        priceCents: 0,
        description: '',
        videoUrl: '',
        image: null,
        purchasable: false,
        recurring: false,
        items: [],
        addons: [],
        worksheetForm: null,
        trelloList: null
      }
    }
  },
  computed: {
    price: {
      get() { return this.product.priceCents / 100 },
      set(val) { this.product.priceCents = parseInt(val * 100, 10) }
    }
  },
  created() {
    const { pathname } = window.location
    this.kinds = [
      { label: 'Product', value: 'product' },
      { label: 'Bundle', value: 'bundle' },
      { label: 'Addon', value: 'addon' }
    ]

    if (pathname.includes('new')) {
      this.isLoading = false
    } else {
      this.isLoading = true
      axios.get(`/ajax${pathname.replace('/edit', '')}`)
        .then((res) => {
          this.isLoading = false
          this.product = res.data
        })
    }
  },
  methods: {
    reformatWorksheetsOptions(arr) {
      return arr.map(({ id, title: text }) => ({ id, text }))
    },
    reformatTrelloListOptions(arr) {
      return arr.map(({ id, name: text }) => ({ id, text }))
    },
    onBundleOptionSelected(product) {
      const item = this.product.items.find((i) => i.product.id === product.id)

      if (!item) return this.product.items.push({ product, count: 1 })
      if (item.destroy) {
        item.destroy = false
        return null
      }
      item.count++

      return null
    },
    onAddonOptionSelected(addon) {
      const item = this.product.addons.find((i) => i.addon.id === addon.id)

      if (!item) return this.product.addons.push({ addon, count: 1 })
      if (item.destroy) item.destroy = false
      return null
    },
    onBundleItemCountChanged({ item, count }) {
      item.count = count
    },
    onItemDelete(key, item) {
      if (item.id) {
        Vue.set(item, 'destroy', true)
      } else {
        this.product[key] = this.product[key].filter((el) => el !== item)
      }
    },
    onSubmit() {
      const data = new FormData(this.$refs.form)
      data.set('product[price_cents]', this.product.priceCents)
      data.set('product[worksheet_form_id]', this.product.worksheetForm?.id || '')
      data.set('product[trello_list_id]', this.product.trelloList?.id || '')

      this.isSubmiting = true
      this.errors = {}

      axios({
        method: this.product.id ? 'put' : 'post',
        url: `/ajax/admin/products/${this.product.id || ''}`,
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(
        () => {
          window.location.pathname = '/admin/products'
        },
        (xhr) => {
          const { type, error } = xhr.response.data
          if (type === 'validation') { this.errors = error }
        }
      ).finally(() => { this.isSubmiting = false })
    }
  }
}
</script>
