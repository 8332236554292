<template>
  <div class="border-light shadow-sm mt-4">
    <div class="">
      <ClipLoader v-if="isLoading && cartItems.length === 0" />
      <div class="table-responsive col-md-12 cart" v-if="!fetchingClients && cartItems.length">
        <fieldset :disabled="isProcessing">
          <table class="table table-centered table-nowrap rounded">
            <tbody>
              <CartItem
                v-for="(cartItem, index) in cartItems"
                :key="cartItem.id"
                :cart-item="cartItem"
                :errors="errors[`items[${index}]Client`]"
                :clients="clients"
              />
            </tbody>
          </table>

          <p class="col-md-6">
            Total Price: {{ totalPrice | formatPriceCents }}
            <button type="button" class="btn btn-primary d-block mt-3" @click.prevent="onPlaceOrder">
              <i v-if="isProcessing" class="fas fa-fw fa-spin fa-spinner" />
              Place Order
            </button>
          </p>
        </fieldset>
      </div>
      <div v-if="!isLoading && cartItems.length === 0" class="alert alert-warning fade show mb-0" role="alert">
        <span class="alert-inner--icon"><i class="fas fa-exclamation-circle" /></span>
        <span class="alert-inner--text">You have no items in your cart</span>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from './index/cart_item'

export default {
  components: { CartItem },
  data() {
    return {
      isProcessing: false,
      errors: {},
      fetchingClients: true,
      clients: []
    }
  },
  computed: {
    isLoading() {
      return Styxie.Cart.isLoading || this.fetchingClients
    },
    cartItems() {
      return Styxie.Cart.cart.items
    },
    totalPrice() {
      return this.cartItems.reduce((memo, { product, count, selectedAddons }) => {
        const addonsCost = Object.keys(selectedAddons).reduce((memo1, id) => {
          const cost = product.addons.find(({ addon }) => addon.id === id).addon.priceCents * selectedAddons[id]
          return memo1 + cost
        }, 0)
        return memo + product.priceCents * count + addonsCost
      }, 0)
    }
  },
  created() {
    axios.get('/ajax/clients')
      .then((res) => {
        this.fetchingClients = false
        this.clients = res.data.rows.map(({ businessName, id }) => ({ businessName, id }))
      })
  },
  methods: {
    onPlaceOrder() {
      this.isProcessing = true
      this.errors = {}
      axios.post('/ajax/orders')
        .then(
          (res) => {
            window.location.pathname = `/orders/${res.data.id}`
          },
          (xhr) => {
            const { type, error } = xhr.response.data
            if (type === 'validation') { this.errors = error }
          }
        )
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}
</script>
