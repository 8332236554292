<template>
  <button class="btn btn-secondary" @click="formState = 'form'" v-if="formState === 'button'">
    Fill required data
  </button>
  <form action="/" @submit.prevent="onSubmit" v-else-if="formState === 'form'">
    <slot name="hidden-fields" />
    <fieldset :disabled="isSubmiting">
      <component
        v-for="input in inputs"
        :is="input.is"
        :key="input.key"
        :errors="errors[input.key]"
        v-model="model[input.key]"
        v-bind="input.attrs"
      />
      <FormSubmit :is-submiting="isSubmiting">
        <template #secondButton>
          <button class="btn btn-link" type="button" @click.prevent="formState = 'button'">Cancel</button>
        </template>
      </FormSubmit>
    </fieldset>
  </form>
  <dl class="row" v-else-if="formState === 'filled'">
    <template v-for="{ label, key, kind } in worksheetForm.inputs">
      <dt class="col-sm-3" :key="`label_${key}`">{{ label }}</dt>
      <dd class="col-sm-9" :key="`value_${key}`">
        <a v-if="kind === 'attachment' && worksheet.values[camelcase(key)]"
           :href="worksheet.values[camelcase(key)]"
           target="_blank">
          Download
        </a>
        <template v-else>{{ worksheet.values[camelcase(key)] }}</template>
      </dd>
    </template>
  </dl>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'
import camelcase from 'camelcase'

import { JSON_TYPE } from '../../../../common/axios'

import {
  FormInput, FormTextarea, FormCheckbox, FormSubmit, FormFile
} from '../../../components/form'

const INPUTS_MAP = {
  string: { is: 'FormInput', attrs: {} },
  number: { is: 'FormInput', attrs: { type: 'number' } },
  boolean: { is: 'FormCheckbox', attrs: {} },
  email: { is: 'FormInput', attrs: { type: 'email' } },
  phone: { is: 'FormInput', attrs: {} },
  text: { is: 'FormTextarea', attrs: {} },
  attachment: { is: 'FormFile', attrs: {} }
}

export default {
  components: {
    FormInput, FormTextarea, FormCheckbox, FormSubmit, FormFile
  },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    product: { type: Object, required: true },
    worksheetForm: { type: Object, required: true },
    worksheet: { type: Object }
  },
  data() {
    return {
      model: null,
      formState: 'button',
      isSubmiting: false,
      errors: {}
    }
  },
  watch: {
    worksheet() {
      this.formState = 'filled'
    }
  },
  created() {
    if (this.worksheet) this.formState = 'filled'

    this.resetModel()

    this.inputs = this.worksheetForm.inputs.map(({ key, kind, label }) => {
      const { is, attrs } = INPUTS_MAP[kind]
      const hsh = { is: is, attrs: { ...attrs } }

      hsh.attrs.label = label
      hsh.attrs.id = [key, this.product.id, this.index].join('_')
      hsh.attrs.name = `worksheet[values][${key}]`

      return { key, ...hsh }
    })
  },
  methods: {
    resetModel() {
      this.model = this.worksheetForm.inputs.reduce((memo, { key }) => {
        memo[key] = null
        return memo
      }, {})
    },
    onSubmit(e) {
      const data = new FormData(e.target)
      data.append('worksheet[order_item_id]', this.item.id)
      data.append('worksheet[worksheet_form_id]', this.worksheetForm.id)

      axios.post('/ajax/worksheets', data, {
        transformResponse: (res, headers) => {
          if (res && headers['content-type'].includes(JSON_TYPE)) {
            const json = JSON.parse(res)
            return json.error ? json : camelCaseKeys(json, { deep: true })
          }
          return res
        }
      })
        .then(
          (res) => {
            this.resetModel()
            this.formState = 'button'
            this.$emit('addWorksheet', res.data)
          },
          (xhr) => {
            const { type, error } = xhr.response.data
            if (type === 'validation') { this.errors = error }
          }
        ).finally(() => { this.isSubmiting = false })
    },
    camelcase: camelcase
  }
}
</script>
