import VueOrdersIndex from '../../vue/pages/admin/orders'
import VueOrderShow from '../../vue/pages/admin/orders/show'

Styxie.Initializers.AdminOrders = {
  index() {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-orders'),
        render: (h) => h(VueOrdersIndex)
      })
    })
  },
  show() {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-order'),
        render: (h) => h(VueOrderShow)
      })
    })
  }
}
