<template>
  <div class="col-12 mt-5">
    <VueSlimTable
      remote-url="/ajax/admin/orders"
      :columns="columns"
      table-class="table table-stripped">
      <template #cell:id="{ value }">
        <a :href="`/admin/orders/${value}`">{{ value }}</a>
      </template>
      <template #cell:paid="{ row }">
        {{ row.aasm_state !== 'pending' ? 'Yes' : 'No' }}
      </template>
      <template #cell:total_price_cents="{ value }">
        {{ value | formatPriceCents }}
      </template>
      <template #cell:created_at="{ value }">
        {{ value | formatIsoDate }}
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
export default {
  created() {
    this.columns = [
      { title: 'Id', key: 'id' },
      { title: 'Paid', key: 'paid' },
      { title: 'Price', key: 'total_price_cents' },
      { title: 'State', key: 'aasm_state' },
      { title: 'Created', key: 'created_at' }
    ]
  }
}
</script>
