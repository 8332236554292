import VueProductsIndex from '../../vue/pages/admin/products'
import VueProductForm from '../../vue/pages/admin/products/form'

function form() {
  document.addEventListener('DOMContentLoaded', () => {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-product'),
      render: (h) => h(VueProductForm)
    })
  })
}

Styxie.Initializers.AdminProducts = {
  index() {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-products'),
        render: (h) => h(VueProductsIndex)
      })
    })
  },
  new: form,
  edit: form
}
