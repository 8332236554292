<template>
  <div class="form-group">
    <label v-if="label" :for="id">{{ label }}</label>
    <multiselect
      :name="name"
      :value="value"
      :options="options"
      :placeholder="placeholder"
      :label="optionLabel"
      :track-by="optionKey"
      :loading="isSearching"
      @search-change="asyncSearch"
      @input="onInput"
    />
    <small v-if="hasErrors" class="form-text text-danger">{{ errors.join(', ') }}</small>
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import fieldMixin from './field_mixin'

export default {
  components: { Multiselect },
  mixins: [fieldMixin],
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || typeof prop === 'object' || prop === null
    },
    placeholder: {
      type: String,
      default: 'Select one'
    },
    optionLabel: {
      type: String,
      default: 'text'
    },
    optionKey: {
      type: String,
      default: 'id'
    },
    url: {
      type: String,
      required: true
    },
    reformatOptions: {
      type: Function,
      default(arr) {
        return arr
      }
    }
  },
  data() {
    return {
      options: [],
      isSearching: false
    }
  },
  created() {
    this.asyncSearch('')
  },
  methods: {
    asyncSearch(q) {
      this.isSearching = true
      axios.get(`${this.url}?search=${q}`)
        .then((res) => {
          this.options = this.reformatOptions(res.data)
          this.isSearching = false
        })
    }
  }
}
</script>
