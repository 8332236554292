export default function normalizeData(arr, { key = 'id', elMutation = false, callback = false } = {}) {
  return arr.reduce((memo, el) => {
    if (el[key]) {
      callback && callback(el) // eslint-disable-line no-unused-expressions
      memo.allIds.push(el[key])
      memo.byId[el[key]] = elMutation ? elMutation(el) : el
    }

    return memo
  }, { byId: {}, allIds: []})
}
