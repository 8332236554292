<template>
  <div class="form-group">
    <label v-if="label" :for="id">{{ label }}</label>
    <multiselect
      :name="name"
      :value="selected"
      :options="options"
      :placeholder="placeholder"
      :label="optionLabel"
      :track-by="optionKey"
      @input="onInput"
    />
    <small v-if="hasErrors" class="form-text text-danger">{{ errors.join(', ') }}</small>
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import fieldMixin from './field_mixin'

export default {
  components: { Multiselect },
  mixins: [fieldMixin],
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || typeof prop === 'number' || typeof prop === 'object' || prop === null
    },
    options: {
      required: true,
      type: Array
    },
    placeholder: {
      type: String,
      default: 'Select one'
    },
    optionLabel: {
      type: String,
      default: 'text'
    },
    optionKey: {
      type: String,
      default: 'id'
    }
  },
  computed: {
    selected() {
      return this.options.find((el) => el[this.optionKey] === this.value)
    }
  }
}
</script>
