<template>
  <li class="nav-item">
    <a href="/cart" class="nav-link d-flex align-items-center justify-content-between">
      <span>
        <span class="sidebar-icon">
          <span class="fas fa-shopping-cart" />
        </span>
        Cart
      </span>
      <span class="badge badge-md badge-danger badge-pill">
        <i v-if="isLoading && count" class="fas fa-spin fa-spinner" />
        <template v-else-if="count">{{ count }}</template>
      </span>
    </a>
  </li>
</template>

<script>
import camelCaseKeys from 'camelcase-keys'
import camelCaseKeysDeep from 'camelcase-keys-deep'

function transformResponse(data, _headers) {
  const { items } = JSON.parse(data)
  return {
    items: items.map((item) => camelCaseKeys({ ...item, product: camelCaseKeysDeep(item.product) }))
  }
}

export default {
  data() {
    return {
      isLoading: true,
      cart: {
        items: []
      }
    }
  },
  computed: {
    count() {
      return this.cart.items.reduce((memo, { count }) => memo + count, 0)
    }
  },
  created() {
    this.fetchCart()
  },
  methods: {
    fetchCart() {
      this.isLoading = true
      axios.get('/ajax/cart', { transformResponse })
        .then((res) => {
          this.cart = res.data
          this.isLoading = false
        })
    },
    addItem({ id: productId }, count = 1) {
      this.isLoading = true
      return axios.post('/ajax/cart/add_item', { cart: { productId, count } }, { transformResponse })
        .then((res) => {
          this.cart = res.data
          this.isLoading = false
        })
    },
    decrementOrRemoveItem({ id: productId }, count = 1) {
      this.isLoading = true
      return axios.post('/ajax/cart/remove_item', { cart: { productId, count } }, { transformResponse })
        .then((res) => {
          this.cart = res.data
          this.isLoading = false
        })
    },
    changeItem(item, payload) {
      this.isLoading = true
      return axios.put(`/ajax/cart/items/${item.id}`, { cartItem: payload }, { transformResponse })
        .then((res) => {
          this.cart = res.data
          this.isLoading = false
        })
    },
    removeItem(item) {
      this.isLoading = true
      return axios.delete(`/ajax/cart/items/${item.id}`, { transformResponse })
        .then((res) => {
          this.cart = res.data
          this.isLoading = false
        })
    },
    addAddonForItem(item, { addon }) {
      this.isLoading = true
      return axios.post(`/ajax/cart/items/${item.id}/add_addon`, { addon_id: addon.id }, { transformResponse })
        .then((res) => {
          this.cart = res.data
          this.isLoading = false
        })
    },
    removeAddonForItem(item, { addon }) {
      this.isLoading = true
      return axios.post(`/ajax/cart/items/${item.id}/remove_addon`, { addon_id: addon.id }, { transformResponse })
        .then((res) => {
          this.cart = res.data
          this.isLoading = false
        })
    }

    // mergeSimilar({ product, clientId }) {
    //   this.isLoading = true
    //   return axios.put(`/ajax/cart/merge_similar_items`, {  })
    //     .then((res) => {
    //       this.cart = res.data
    //       this.isLoading = false
    //     })
    // }
  }
}
</script>
