var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mt-5"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"/admin/products/new"}},[_vm._v("New")]),_vm._v(" "),_c('VueSlimTable',{attrs:{"remote-url":"/ajax/admin/products","columns":_vm.columns,"table-class":"table table-stripped"},scopedSlots:_vm._u([{key:"cell:id",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":("/admin/products/" + value + "/edit")}},[_vm._v(_vm._s(value))])]}},{key:"cell:kind",fn:function(ref){
var value = ref.value;
return [_c('span',{class:['badge badge-lg', { 'badge-primary': value === 'product', 'badge-tertiary': value === 'bundle', 'badge-info': value === 'addon' }]},[_vm._v("\n        "+_vm._s(value)+"\n      ")])]}},{key:"cell:price_cents",fn:function(ref){
var value = ref.value;
return [_vm._v("\n      "+_vm._s(value / 100)+"\n    ")]}},{key:"cell:items",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(item){return _c('div',{key:item.id},[_c('a',{attrs:{"href":("/admin/products/" + (item.id) + "/edit")}},[_vm._v(_vm._s(item.product.title)+" *"+_vm._s(item.count))])])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }