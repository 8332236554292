<template>
  <div class='card p-3 my-3'>
    <div class='row'>
      <div class='col'>
        <h1>{{ cartItem.product.title }}</h1>
        <button class="btn mr-2 btn-icon-only btn-light" type="button" @click.prevent="incrementItem">
          <span class="btn-inner-icon"><i class="fas fa-fw fa-plus" /></span>
        </button>
        {{ cartItem.count }}
        <button class="btn ml-2 btn-icon-only btn-light" type="button" @click.prevent="decrementOrRemoveItem">
          <span class="btn-inner-icon"><i class="fas fa-fw fa-minus" /></span>
        </button>
      </div>
      <div class="col">
        <FormSelectSync :options="clients"
                        :label="false"
                        placeholder="Pick client"
                        :id="`clients_${cartItem.id}`"
                        option-label="businessName"
                        option-key="id"
                        :errors="errors"
                        :value="cartItem.clientId"
                        @input="clientSelected($event)" />
      </div>
      <div class="col">
        <button class="btn mr-2 btn-light" type="button" @click.prevent="cloneItem">
          <span class="btn-inner-icon">
            <i class="fas fa-clone" />
          </span>
          Clone
        </button>
        <button class="btn mr-2 btn-icon-only btn-danger" type="button" @click.prevent="removeItem">
          <span class="btn-inner-icon"><i class="fas fa-fw fa-trash" /></span>
        </button>
        <h1 class='float-right'><span class='badge badge-success'>{{ cartItem.product.priceCents | formatPriceCents }}</span></h1>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col">
        <h3>Choose Your Addons</h3>
        <p>Addons can power up your purchases and decrease delivery time.</p>
        <template v-if="cartItem.product.addons.length">
          <div v-for="prAddon in cartItem.product.addons" :key="prAddon.id" class="mt-2">
            <button :class="['btn mr-2 btn-icon-only btn-light btn-sm', { invisible: cartItem.selectedAddons[prAddon.addon.id] === cartItem.count }]"
                    type="button"
                    @click.prevent="addAddon(prAddon)">
              <span class="btn-inner-icon"><i class="fas fa-fw fa-plus" /></span>
            </button>
            {{ cartItem.selectedAddons[prAddon.addon.id] || 0 }}
            <button :class="['btn ml-2 btn-icon-only btn-secondary btn-sm', { invisible: !cartItem.selectedAddons[prAddon.addon.id] }]"
                    type="button"
                    @click.prevent="removeAddon(prAddon)">
              <span class="btn-inner-icon"><i class="fas fa-fw fa-minus" /></span>
            </button>

            {{ prAddon.addon.priceCents | formatPriceCents }} {{ prAddon.addon.title }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { confirmWithSwal } from '../../../../common/swal'
import FormSelectSync from '../../../components/form/select_sync'

export default {
  components: { FormSelectSync },
  props: {
    cartItem: { type: Object, required: true },
    errors: { type: Array },
    clients: { type: Array }
  },
  computed: {
    similar() {
      return Styxie.Cart.cart.items.filter((el) => el.product.id === this.cartItem.product.id && el.clientId === this.cartItem.clientId)
    }
  },
  methods: {
    incrementItem() {
      Styxie.Cart.changeItem(this.cartItem, { count: this.cartItem.count + 1 })
    },
    decrementOrRemoveItem() {
      const newCount = this.cartItem.count - 1
      if (newCount) {
        Styxie.Cart.changeItem(this.cartItem, { count: this.cartItem.count - 1 })
      } else {
        this.removeItem()
      }
    },
    removeItem() {
      confirmWithSwal({
        submit: () => Styxie.Cart.removeItem(this.cartItem),
        text: ''
      })
    },
    clientSelected(client) {
      Styxie.Cart.changeItem(this.cartItem, { clientId: client?.id || null })
    },
    // mergeSimilar() {
    //   Styxie.Cart.mergeSimilar(this.cartItem)
    // },
    cloneItem() {
      Styxie.Cart.addItem(this.cartItem.product)
    },
    addAddon(addon) {
      Styxie.Cart.addAddonForItem(this.cartItem, addon)
    },
    removeAddon(addon) {
      Styxie.Cart.removeAddonForItem(this.cartItem, addon)
    }
  }
}
</script>
