<template>
  <div class="card border-bottom rounded-0 py-3">
    <div class="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0 pl-sm-0">
      <div class="col-10 col-lg-8 d-block px-0 mb-4 mb-md-0 ml-3">
        <h3 class="h5">{{ product.title }}</h3>
        <span :class="badgeClass">{{ item.state }}</span>
        <span :class="badgeClass">{{ item.aasmState }}</span>
        <div class="d-sm-flex">
          <span class="h6 font-weight-normal text-gray mb-3 mb-sm-0">
            Cost: {{ product.priceCents | formatPriceCents }}
          </span>
          <span class="h6 font-weight-normal mx-4">Quantity: ×{{ item.count }}</span>
          <span class="h6 text-gray mb-3 mb-sm-0">
            Total: {{ product.priceCents * item.count | formatPriceCents }}
          </span>
        </div>

        <hr>

        <div v-if="item.attachments">
          <div v-for="(attachment, index) in item.attachments" :key="index">
            <a :href="attachment.url">{{ attachment.name }}</a>
          </div>s
        </div>

        <div class='pb-2'>
          <h5>Client</h5>
          <a :href="`/clients/${client.id}/edit`">{{ client.name }}</a>
        </div>

        <hr>

        <div v-if="product.worksheetFormId && order.aasmState !== 'pending'" class="font-weight-normal text-gray">
          <div v-for="index in item.count" :key="index">
            <Worksheet
              :item="item"
              :worksheet-form="wsfs.byId[product.worksheetFormId]"
              :worksheet="wss.byId[itemWssIds[index - 1]]"
              :product="product"
              :index="index - 1"
              @addWorksheet="addWorksheet"
            />
            <hr>
          </div>
        </div>

        <div v-if="Object.keys(item.selectedAddons).length">
          <h5>Add Ons</h5>
          <div v-for="(count, id) in item.selectedAddons" :key="id">
            <strong>{{ products.byId[id].title }}</strong>
            <div class="d-sm-flex">
              <span class="h6 font-weight-normal text-gray mb-3 mb-sm-0">
                {{ products.byId[id].priceCents | formatPriceCents }}
              </span>
              <span class="h6 mx-4">×{{ count }}</span>
              <span class="h6 font-weight-normal text-gray mb-3 mb-sm-0">
                {{ products.byId[id].priceCents * count | formatPriceCents }}
              </span>
            </div>
            <template v-if="products.byId[id].worksheetFormId && order.aasmState !== 'pending'">
              <div v-for="index in count" :key="index">
                <Worksheet
                  :item="{ ...products.byId[id], id: item.id }"
                  :worksheet-form="wsfs.byId[products.byId[id].worksheetFormId]"
                  :worksheet="wss.byId[addonsWss[id][index - 1]]"
                  :product="products.byId[id]"
                  :index="index - 1"
                  @addWorksheet="addWorksheet">
                  <template #hidden-fields>
                    <input type="hidden" name="worksheet[addon_id]" :value="id">
                  </template>
                </Worksheet>
                <hr>
              </div>
            </template>
          </div>
        </div>

        <h3>Comments</h3>
        <OrderItemComments :comments="itemComments" :order-item-id="item.id" @addComment="addComment" />
      </div>
    </div>
  </div>
</template>

<script>
import Worksheet from './worksheet'
import OrderItemComments from '../../../components/order_item_comments'

export default {
  components: { Worksheet, OrderItemComments },
  props: {
    order: { type: Object, required: true },
    item: { type: Object, required: true },
    product: { type: Object, required: true },
    client: { type: Object, required: true },
    comments: { type: Object, required: true },
    products: { type: Object, required: true },
    wsfs: { type: Object, required: true },
    wss: { type: Object, required: true }
  },
  computed: {
    badgeClass() {
      return {
        'badge badge-success': this.item.aasmState === 'finished',
        'badge badge-info': this.item.aasmState === 'in_progress',
        'badge badge-warning': this.item.aasmState === 'pending_client_input'
      }
    },
    itemComments() {
      return this.item.commentIds.map((id) => this.comments.byId[id])
    },
    itemWssIds() {
      return this.wss.allIds.filter((id) => this.wss.byId[id].orderItemId === this.item.id && this.wss.byId[id].addonId === null)
    },
    addonsWss() {
      return Object.keys(this.item.selectedAddons).reduce((memo, addonId) => {
        memo[addonId] = this.wss.allIds.filter((wsId) => {
          const ws = this.wss.byId[wsId]
          return ws.orderItemId === this.item.id && ws.addonId === addonId
        })
        return memo
      }, {})
    }
  },
  methods: {
    addWorksheet(ws) {
      this.$emit('addWorksheet', ws)
    },
    addComment(comment) {
      this.$emit('addComment', { comment, item: this.item })
    }
  }
}
</script>
