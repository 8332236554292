import VueCartIndex from '../vue/pages/cart'

Styxie.Initializers.Carts = {
  show() {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-cart'),
        render: (h) => h(VueCartIndex)
      })
    })
  }
}
