import VueCartNav from '../vue/components/cart_nav'

Styxie.Initializers.Global = {
  index() {
    document.addEventListener('DOMContentLoaded', () => {
      Styxie.Cart = new Vue({ // eslint-disable-line no-new, prefer-destructuring
        el: document.getElementById('vue-cart-nav'),
        render: (h) => h(VueCartNav)
      }).$children[0]
    })
  }
}
