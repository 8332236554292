<template>
  <div class="card card-body border-light shadow-sm table-wrapper table-responsive mt-4">
    <div class="row">
      <!-- <div class="col-2">
        <TableFilters
          :categories-list="categories"
          :filter-ranges="filterRanges"
          @onFilterChange="onFilterChange"
        />
      </div> -->
      <div class="col-12">
        <VueSlimTable
          remote-url="/ajax/guest_posts"
          :columns="columns"
          table-class="table"

          wrapper-class="col-12"
          per-page-key="per"
          :per-page="10"
        >
          <template #cell:checkbox="{ row }">
            <div class="form-check dashboard-check">
              <input class="form-check-input" type="checkbox" value="" :id="`checkbox_${row.id}`">
              <label class="form-check-label" :for="`checkbox_${row.id}`" />
            </div>
          </template>
        </VueSlimTable>
      </div>
    </div>
  </div>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'

// import TableFilters from './index/filters'

export default {
  // components: { TableFilters },
  props: {
    categories: { type: Array, required: true },
    filterRanges: { type: Object, required: true }
  },
  data() {
    return {
      filters: {
        byCategory: null,
        byAhrefsHostDomainRatingRange: null,
        byAhrefsUrlRatingRange: null,
        byTrustFlowRange: null,
        byCitationFlowRange: null,
        byDaRange: null,
        byPaRange: null,
        byMozRankRange: null,
        byRetailPriceRange: null
      }
    }
  },
  computed: {
    customFilters() {
      return {
        ...snakeCaseKeys(this.filters, { deep: true })
      }
    }
  },
  created() {
    this.columns = [
      { title: '', key: 'checkbox' },
      { title: 'Url', key: 'url', orderable: true },
      { title: 'DR', key: 'ahrefs_host_domain_rating', orderable: true },
      { title: 'UR', key: 'ahrefs_url_rating', orderable: true },
      { title: 'TF', key: 'trust_flow', orderable: true },
      { title: 'CF', key: 'citation_flow', orderable: true },
      { title: 'DA', key: 'da', orderable: true },
      { title: 'PA', key: 'pa', orderable: true },
      { title: 'MozRank', key: 'moz_rank', orderable: true },
      { title: 'Price', key: 'retail_price', orderable: true }
    ]
  },
  methods: {
    onFilterChange({ key, val }) {
      this.filters[key] = val
    }
  }
}
</script>
