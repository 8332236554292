<template>
  <div class="row justify-content-md-center">
    <ClipLoader v-if="isLoading" />
    <AppProduct v-else v-for="product in products" :key="product.id" :product="product" />
  </div>
</template>

<script>
import AppProduct from './index/product'

export default {
  components: { AppProduct },
  data() {
    return {
      isLoading: true,
      products: []
    }
  },
  created() {
    axios.get('/ajax/products?kind[]=product&kind[]=bundle')
      .then((res) => {
        this.isLoading = false
        this.products = res.data
      })
  }
}
</script>
