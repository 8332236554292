import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys-deep'
import Qs from 'qs'

export const JSON_TYPE = 'application/json' // eslint-disable-line import/prefer-default-export
const csrf = document.querySelector('meta[name="csrf-token"]')
axios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')

axios.defaults.transformResponse = [(data, headers) => {
  if (data && headers['content-type'].includes(JSON_TYPE)) {
    return camelCaseKeys(JSON.parse(data), { deep: true })
  }
  return data
}]

axios.defaults.transformRequest = [(data) => {
  if (data && !(data instanceof FormData)) {
    return Qs.stringify(snakeCaseKeys(data, { deep: true }), { arrayFormat: 'brackets' })
  }
  return data
}]
