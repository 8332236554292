<template>
  <div class="form-group custom-file mb-3">
    <input
      type="file"
      :class="['custom-file-input', { 'is-invalid': hasErrors }]"
      :id="id"
      :name="name"
      @input="onInput($event.target.files[0])">
    <label class="custom-file-label" :for="id">{{ displayedLabel }}</label>
    <small v-if="hasErrors" class="form-text text-danger">{{ errors.join(', ') }}</small>
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import fieldMixin from './field_mixin'

export default {
  mixins: [fieldMixin],
  props: {
    value: {
      type: [File, Object]
    }
  },
  computed: {
    displayedLabel() {
      return this.value?.name || this.label
    }
  }
}
</script>
