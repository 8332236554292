import VueOrder from '../vue/pages/orders/show'

Styxie.Initializers.Orders = {
  show() {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-order'),
        render: (h) => h(VueOrder)
      })
    })
  }
}
