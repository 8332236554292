import Swal from 'sweetalert2'

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const DEFAULT_SWAL_OPTIONS = {
  title: 'Are you sure?',
  text: 'Once deleted, you will not be able to recover this!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes!'
}

export function confirmWithSwal({ submit, reject, ...options }) {
  return Swal.fire({
    ...DEFAULT_SWAL_OPTIONS,
    ...options
  }).then((res) => {
    if (res.value) {
      typeof submit === 'function' && submit() // eslint-disable-line no-unused-expressions
    } else {
      typeof reject === 'function' && reject() // eslint-disable-line no-unused-expressions
    }
  })
}
