<template>
  <div class="form-group">
    <button type="submit" class="btn btn-primary">
      <slot name="save">
        <i v-if="isSubmiting" class="fas fa-spin fa-spinner" />
        <template v-else>Save</template>
      </slot>
    </button>
    <slot name="secondButton">
      <a v-if="backLink" :href="backLink" class="btn btn-link">Back</a>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    backLink: {
      type: String
    },
    isSubmiting: {
      type: Boolean,
      default: false
    }
  }
}
</script>
