import VueClientsIndex from '../vue/pages/clients'
import VueClientForm from '../vue/pages/clients/form'

function form() {
  document.addEventListener('DOMContentLoaded', () => {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-client-form'),
      render: (h) => h(VueClientForm)
    })
  })
}

Styxie.Initializers.Clients = {
  index() {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-clients'),
        render: (h) => h(VueClientsIndex)
      })
    })
  },
  new: form,
  edit: form
}
