Styxie.Initializers.Welcome = {
  index(_params) {
    document.addEventListener('DOMContentLoaded', () => {
      const $preloader = $('.preloader')
      if ($preloader.length) {
        $preloader.delay(500).fadeOut('slow')
      }
    })
  },
  authority_stack(_params) {
    document.addEventListener('DOMContentLoaded', () => {
      const $preloader = $('.preloader')
      if ($preloader.length) {
        $preloader.delay(500).fadeOut('slow')
      }
    })
  }
}
