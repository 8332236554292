require("@rails/ujs").start()
// require("turbolinks").start()
require("javascripts/channels")

import '../stylesheets/application'

import Vue from 'vue'

// import 'jquery'
// import 'popper.js'
import 'bootstrap'

import '../javascripts/common/axios'
import '../javascripts/vue/components'

const styxies = require.context('../javascripts/styxies', true, /\.js$/)
styxies.keys().forEach(styxies)

//import '~rocket/assets/img/team/profile-picture-3.jpg'
//import 'rocket/assets/img/brand/light.svg'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
