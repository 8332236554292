<template>
  <div class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0">
    <VueSlimTable
      remote-url="/ajax/clients"
      :columns="columns"
      table-class="table table-hover"
      wrapper-class="col-md-12"
    />
  </div>
</template>

<script>
export default {
  created() {
    this.columns = [
      { title: 'Name', key: 'business_name', orderable: true },
      { title: 'Date Added', key: 'created_at', orderable: true },
      { title: 'City', key: 'city', orderable: true },
      { title: 'State', key: 'state', orderable: true },
      { title: 'Zip', key: 'zip', orderable: true },
      { title: 'Phone', key: 'phone_number', orderable: true },
      { title: 'Email', key: 'email', orderable: true }
    ]
  }
}
</script>
