import VueProductsIndex from '../vue/pages/products/index'

Styxie.Initializers.Products = {
  index() {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-products'),
        render: (h) => h(VueProductsIndex)
      })
    })
  }
}
