<template>
  <dl class="row mb-0">
    <dt class="col-sm-2">
      <a :href="`/admin/products/${product.id}/edit`">{{ product.title }}</a>
      <small class="d-block">
        {{ client.name }}
      </small>
      <span :class="badgeClass">{{ item.aasmState }}</span>
      <button
        v-if="pendingTeamAction"
        class="py-2 btn btn-success"
        @click='completeOrderItem(item)'>
        Order Completed
      </button>
    </dt>
    <dd class="col-sm-9">
      <div class="d-block d-sm-flex">
        <span class="h6 font-weight-normal text-gray mb-3 mb-sm-0">{{ product.priceCents | formatPriceCents }}</span>
        <span class="h6 mx-4">×{{ item.count }}</span>
        <span class="h6 font-weight-normal text-gray mb-3 mb-sm-0">{{ product.priceCents * item.count | formatPriceCents }}</span>
      </div>

      <p v-if="wsf" class="mb-0">{{ (itemWss || []).length }} / {{ item.count }} forms filled</p>

      <a v-if="!showForms && itemWss.length" href="#" @click.prevent="showForms = true">Show Forms</a>

      <div v-else-if="itemWss.length">
        <dl class="row" v-for="id in itemWss" :key="id">
          <template v-for="{ label, key, kind } in wsf.inputs">
            <dt class="col-sm-3" :key="`label_${key}`">{{ label }}</dt>
            <dd class="col-sm-9" :key="`value_${key}`">
              <a v-if="kind === 'attachment' && wss.byId[id].values[camelcase(key)]"
                 :href="wss.byId[id].values[camelcase(key)]"
                 target="_blank">
                Download
              </a>
              <template v-else>{{ wss.byId[id].values[camelcase(key)] }}</template>
            </dd>
          </template>
        </dl>
        <hr>
      </div>

      <div v-if="item.selectedAddons">
        <div v-for="(count, id) in item.selectedAddons" :key="id" class="mt-2">
          <strong>{{ products.byId[id].title }}</strong>

          <div class="d-sm-flex">
            <span class="h6 font-weight-normal text-gray mb-3 mb-sm-0">
              {{ products.byId[id].priceCents | formatPriceCents }}
            </span>
            <span class="h6 mx-4 mb-0">×{{ count }}</span>
            <span class="h6 font-weight-normal text-gray mb-3 mb-sm-0">
              {{ products.byId[id].priceCents * item.count | formatPriceCents }}
            </span>
          </div>

          <p v-if="products.byId[id].worksheetFormId" class="mb-0">{{ (addonsWss[id] || []).length }} / {{ count }} forms filled</p>
          <div v-if="showForms && addonsWss[id].length">
            <dl class="row" v-for="wsId in addonsWss[id]" :key="wsId">
              <template v-for="{ label, key, kind } in wsfs.byId[products.byId[id].worksheetFormId].inputs">
                <dt class="col-sm-3" :key="`label_${key}`">{{ label }}</dt>
                <dd class="col-sm-9" :key="`value_${key}`">
                  <a v-if="kind === 'attachment' && wss.byId[wsId].values[camelcase(key)]"
                     :href="wss.byId[wsId].values[camelcase(key)]"
                     target="_blank">
                    Download
                  </a>
                  <template v-else>{{ wss.byId[wsId].values[camelcase(key)] }}</template>
                </dd>
              </template>
            </dl>
            <hr>
          </div>
          <hr>
        </div>
      </div>
      <hr v-else>
      <h3>Comments</h3>
      <OrderItemComments :comments="itemComments" :order-item-id="item.id" @addComment="addComment" />
    </dd>
  </dl>
</template>

<script>
import camelcase from 'camelcase'
import OrderItemComments from '../../../../components/order_item_comments'

export default {
  components: {
    OrderItemComments
  },
  props: {
    item: { type: Object, required: true },
    product: { type: Object, required: true },
    client: { type: Object, required: true },
    comments: { type: Object, required: true },
    products: { type: Object, required: true },
    wsfs: { type: Object, required: true },
    wss: { type: Object, required: true },
    orderId: { type: String, required: true }
  },
  data() {
    return {
      showForms: false
    }
  },
  computed: {
    wsf() {
      return this.wsfs.byId[this.product.worksheetFormId]
    },
    itemWss() {
      return this.wss.allIds.filter((id) => this.wss.byId[id].orderItemId === this.item.id && this.wss.byId[id].addonId === null)
    },
    itemComments() {
      return this.item.commentIds.map((id) => this.comments.byId[id])
    },
    addonsWss() {
      return Object.keys(this.item.selectedAddons).reduce((memo, addonId) => {
        memo[addonId] = this.wss.allIds.filter((wsId) => {
          const ws = this.wss.byId[wsId]
          return ws.orderItemId === this.item.id && ws.addonId === addonId
        })
        return memo
      }, {})
    },
    badgeClass() {
      return {
        'badge badge-success': this.item.aasmState === 'finished',
        'badge badge-info': this.item.aasmState === 'in_progress',
        'badge badge-warning': this.item.aasmState === 'pending_client_input'
      }
    },
    pendingTeamAction() {
      return this.item.aasmState === 'in_progress'
    }
  },
  methods: {
    camelcase: camelcase,

    addComment(comment) {
      this.$emit('addComment', { comment, item: this.item })
    },

    completeOrderItem(item) {
      axios({
        method: 'post',
        url: `/ajax/admin/orders/${this.orderId}/order_items/${item.id}/complete`
      }).then(
        () => {
        },
        (xhr) => {
          const { type, error } = xhr.response.data
          if (type === 'validation') { this.errors = error }
        }
      ).finally(() => { this.isSubmiting = false })
    }
  }
}
</script>
