<template>
  <div class="col-12 mt-5">
    <a href="/admin/products/new" class="btn btn-primary">New</a>

    <VueSlimTable
      remote-url="/ajax/admin/products"
      :columns="columns"
      table-class="table table-stripped">
      <template #cell:id="{ value }">
        <a :href="`/admin/products/${value}/edit`">{{ value }}</a>
      </template>
      <template #cell:kind="{ value }">
        <span :class="['badge badge-lg', { 'badge-primary': value === 'product', 'badge-tertiary': value === 'bundle', 'badge-info': value === 'addon' }]">
          {{ value }}
        </span>
      </template>
      <template #cell:price_cents="{ value }">
        {{ value / 100 }}
      </template>
      <template #cell:items="{ value }">
        <div v-for="item in value" :key="item.id">
          <a :href="`/admin/products/${item.id}/edit`">{{ item.product.title }} *{{ item.count }}</a>
        </div>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
export default {
  created() {
    this.columns = [
      { title: 'Id', key: 'id' },
      { title: 'Kind', key: 'kind' },
      { title: 'Title', key: 'title' },
      { title: 'Price', key: 'price_cents' },
      { title: 'Items', key: 'items' }
    ]
  }
}
</script>
