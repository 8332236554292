<template>
  <ClipLoader v-if="isLoading" />

  <div v-else class="mt-4">
    <div class="shadow-sm mb-3">
      <dl class="row mb-0">
        <dt class="col-sm-2">Total Price</dt>
        <dd class="col-sm-9">{{ totalPrice | formatPriceCents }}</dd>

        <dt class="col-sm-2">Paid</dt>
        <dd class="col-sm-9">{{ paid ? 'Yes' : 'No' }}</dd>
      </dl>

      <form action="/" @submit.prevent="onCharge" v-if="!paid">
        <div class="card">
          <div class="card-body">
            <div id="card-element" />
            <p class="mt-2 mb-0" :class="chargeClass" v-if="stripeCharge">
              {{ stripeCharge.message }}
            </p>
          </div>
          <div class="card-footer pt-0">
            <button type="submit" class="btn btn-primary">Pay</button>
          </div>
        </div>
      </form>
    </div>

    <div class='py-3' v-for="id in items.allIds" :key="id">
      <OrderItem
        :order="order"
        :item="items.byId[id]"
        :product="products.byId[items.byId[id].purchaseableId]"
        :client="clients.byId[items.byId[id].clientId]"
        :comments="comments"
        :products="products"
        :wsfs="wsfs"
        :wss="wss"
        @addWorksheet="addWorksheet"
        @addComment="addComment"
      />
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys'
import camelCaseKeysDeep from 'camelcase-keys-deep'
import { loadStripe } from '@stripe/stripe-js'

import normalizeData from 'javascripts/common/normalize_data'
import OrderItem from './show/order_item'

function transformResponse(data, _headers) {
  const json = JSON.parse(data)
  return {
    ...camelCaseKeysDeep(json),
    items: json.items.map((item) => camelCaseKeys(item))
  }
}

const MAP_CHARGE_CLASSES = {
  succeeded: 'text-success',
  pending: 'text-warning',
  failed: 'text-danger'
}

export default {
  components: { OrderItem },
  data() {
    return {
      isLoading: true,
      stripeError: null,
      stripeCharge: null,
      order: {},
      items: { byId: {}, allIds: []},
      clients: { byId: {}, allIds: []},
      comments: { byId: {}, allIds: []},
      products: { byId: {}, allIds: []},
      wsfs: { byId: {}, allIds: []},
      wss: { byId: {}, allIds: []}
    }
  },
  computed: {
    totalPrice() {
      return this.items.allIds.reduce((memo, id) => {
        const item = this.items.byId[id]
        const addonsCost = Object.keys(item.selectedAddons).reduce((m, addId) => m + this.products.byId[addId].priceCents * item.selectedAddons[addId], 0)
        return memo + this.products.byId[item.purchaseableId].priceCents * item.count + addonsCost
      }, 0)
    },
    chargeClass() {
      return MAP_CHARGE_CLASSES[this.stripeCharge?.status]
    },
    paid() {
      return this.stripeCharge.status === 'succeeded'
    }
  },
  created() {
    axios.get(`/ajax${window.location.pathname}`, { transformResponse })
      .then((res) => {
        this.order = res.data.order

        this.clients = normalizeData(res.data.clients)
        const itemComments = {}
        this.comments = normalizeData(res.data.comments, {
          callback({ id, orderItemId }) {
            itemComments[orderItemId] = itemComments[orderItemId] || []
            itemComments[orderItemId].push(id)
          }
        })
        this.products = normalizeData(res.data.products)
        this.wsfs = normalizeData(res.data.wsfs)
        this.wss = normalizeData(res.data.wss)

        this.items = normalizeData(res.data.items, {
          elMutation(el) {
            return { ...el, commentIds: itemComments[el.id] || []}
          }
        })

        const { charge, key } = res.data.stripe
        this.stripeCharge = charge || {}

        if (!this.paid) {
          loadStripe(key)
            .then((stripe) => {
              this.stripe = stripe
              this.card = stripe.elements().create('card', {})
              this.card.mount('#card-element')
            })
        }

        this.isLoading = false
      })
  },
  methods: {
    addWorksheet(ws) {
      this.wss.allIds.push(ws.id)
      this.wss.byId[ws.id] = ws

      const item = this.items.byId[ws.orderItemId]
      const productWsAmount = this.products.byId[item.purchaseableId].worksheetFormId ? item.count : 0
      const addonsWsAmount = Object.keys(item.selectedAddons).reduce((memo, key) => {
        const pr = this.products.byId[key]
        return pr.worksheetFormId ? memo + item.selectedAddons[key] : memo
      }, 0)

      const rightWssAmount = productWsAmount + addonsWsAmount

      const currentWssAmount = this.wss.allIds.reduce((memo, id) => {
        if (this.wss.byId[id].orderItemId === item.id) memo += 1
        return memo
      }, 0)

      if (rightWssAmount === currentWssAmount) {
        item.aasmState = 'finished'
      } else {
        item.aasmState = 'in_progress'
      }
    },
    addComment({ comment, item }) {
      this.comments.allIds.push(comment.id)
      this.comments.byId[comment.id] = comment
      item.commentIds.push(comment.id)
    },
    onCharge() {
      this.stripe.createToken(this.card).then((result) => {
        if (result.error) {
          this.stripeError = result.error.message
        } else {
          axios.post(`/ajax/orders/${this.order.id}/pay`, result)
            .then((res) => {
              this.stripeCharge = res.data
              if (this.stripeCharge.status === 'succeeded') this.order.aasmState = 'paid'
            })
        }
      })
    }
  }
}
</script>
