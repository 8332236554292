const FULL_DATE_TIME_FORMAT = {
  year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
}

export default function formatIsoDate(str, format = FULL_DATE_TIME_FORMAT) {
  const dateArr = str.split(/[^0-9]/)
  const date = new Date(Date.UTC(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]))

  return date.toLocaleString(navigator.language, format)
}

Vue.filter('formatIsoDate', formatIsoDate)
