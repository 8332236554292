import VueWebsitesIndex from '../vue/pages/guest_posts/index'

Styxie.Initializers.GuestPosts = {
  index({ categories, filterRanges }) {
    document.addEventListener('DOMContentLoaded', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-websites-table'),
        render: (h) => h(VueWebsitesIndex, { props: { categories, filterRanges } })
      })
    })
  }
}
