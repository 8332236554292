<template>
  <div class="card border-light shadow-sm mb-3">
    <div class="card-header pb-0">
      <h4>
        <slot name="header" />
      </h4>
    </div>
    <div class="card-body">
      <slot
        v-for="(item, i) in items"
        name="item"
        :item="item"
        :index="i" />

      <multiselect
        v-if="showSelect"
        v-model="selectedOption"
        :options="options"
        placeholder="Pick some"
        label="title"
        track-by="id"
        :loading="isSelectSearching"
        @search-change="asyncFindOptions"
        @input="onOptionSelected" />
      <a href="#" @click.prevent="onPlusClicked" v-else>
        <i class="fas fa-plus" />
      </a>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  props: {
    items: { type: Array, required: true },
    queryUrl: { type: String, required: true }
  },
  data() {
    return {
      showSelect: false,
      selectedOption: null,
      options: [],
      isSelectSearching: false
    }
  },
  created() {
    this.asyncFindOptions('')
  },
  methods: {
    onPlusClicked() {
      this.showSelect = true
    },
    asyncFindOptions(q) {
      this.isSelectSearching = true
      axios.get(`${this.queryUrl}&q=${q}`)
        .then((res) => {
          this.options = res.data.rows
          this.isSelectSearching = false
        })
    },
    onOptionSelected(option) {
      this.$emit('onOptionSelected', option)

      this.selectedOption = null
      this.showSelect = false
    }
  }
}
</script>
