<template>
  <div class="row mt-4">
    <div class="col-12 col-md-6">
      <div class="comments">
        <div v-for="comment in comments" :key="comment.id" :class="['comment', { admin: comment.user.isAdmin, user: !comment.user.isAdmin }]">
          <h2 class="h5 mb-0">{{ comment.user.name }}</h2>
          <span class="text-muted">{{ comment.createdAt | formatIsoDate }}</span>
          <p>{{ comment.text }}</p>
        </div>
      </div>

      <form action="/" @submit.prevent="onSubmit">
        <fieldset :disabled="isSubmiting">
          <FormTextarea v-model="commentText"
                        label="Comment"
                        id="text"
                        name="order_item_comment[text]"
                        placeholder="comment"
                        :errors="errors.text" />

          <FormSubmit :is-submiting="isSubmiting" back-link="/admin/orders" />
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import FormTextarea from './form/textarea'
import FormSubmit from './form/submit'

export default {
  components: { FormTextarea, FormSubmit },
  props: {
    comments: {
      type: Array,
      required: true
    },
    orderItemId: String
  },
  data() {
    return {
      isSubmiting: false,
      commentText: '',
      errors: {}
    }
  },
  methods: {
    onSubmit(e) {
      this.isSubmiting = true
      const data = new FormData(e.target)

      axios.post(`/ajax${window.location.pathname}/comments?item_id=${this.orderItemId}`, data)
        .then(
          (res) => {
            this.$emit('addComment', res.data)
            this.commentText = ''
          },
          (xhr) => {
            const { type, error } = xhr.response.data
            if (type === 'validation') { this.errors = error }
          }
        )
        .finally(() => { this.isSubmiting = false })
    }
  }
}
</script>
