var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mt-5"},[_c('VueSlimTable',{attrs:{"remote-url":"/ajax/admin/orders","columns":_vm.columns,"table-class":"table table-stripped"},scopedSlots:_vm._u([{key:"cell:id",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":("/admin/orders/" + value)}},[_vm._v(_vm._s(value))])]}},{key:"cell:paid",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.aasm_state !== 'pending' ? 'Yes' : 'No')+"\n    ")]}},{key:"cell:total_price_cents",fn:function(ref){
var value = ref.value;
return [_vm._v("\n      "+_vm._s(_vm._f("formatPriceCents")(value))+"\n    ")]}},{key:"cell:created_at",fn:function(ref){
var value = ref.value;
return [_vm._v("\n      "+_vm._s(_vm._f("formatIsoDate")(value))+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }