export default {
  props: {
    label: {
      type: [String, Boolean]
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String
    },
    hint: {
      type: String
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    }
  },
  methods: {
    onInput(newVal) {
      this.$emit('input', newVal)
    }
  }
}
