<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <input
      :type="type"
      :class="['form-control', { 'is-invalid': hasErrors }]"
      :id="id"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :step="step"
      @input="onInput($event.target.value)">
    <small v-if="hasErrors" class="form-text text-danger">{{ errors.join(', ') }}</small>
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import fieldMixin from './field_mixin'

export default {
  mixins: [fieldMixin],
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || typeof prop === 'number' || prop === null
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    step: {
      type: [Number, String]
    }
  }
}
</script>
