<template>
  <div class="col-12 col-sm-6 col-xl-4 mb-4">
    <div class="card border-light shadow-sm">
      <div class="card-body">
        <div class="row d-block d-xl-flex align-items-center">
          <div class="col-12 col-xl-3 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
            <img v-if="product.image" :src="product.image" alt="image">
            <div v-else class="icon icon-shape icon-md icon-shape-primary rounded mr-4 mr-sm-0">
              <span class="fas fa-chart-line" />
            </div>
            <div class="d-sm-none">
              <h2 class="h5">{{ product.title }}</h2>
              <h3 class="mb-1">{{ product.priceCents | formatPriceCents }}</h3>
            </div>
          </div>
          <div class="col-12 col-xl-9 px-xl-0">
            <div class="d-none d-sm-block text-center">
              <h2 class="h5">{{ product.title }}</h2>
              <hr>
              <h2 class="mb-1 text-primary">{{ product.priceCents | formatPriceCents }}</h2>
            </div>
            <!-- <small>Rating: ???</small> -->
            <hr>
          </div>
          <hr>
          <div class="col-12 col-xl-12 px-xl-0 p-2 text-center">
            <div class="d-none d-sm-block">
              <p>
                {{ product.description }}
              </p>
              <p v-if="product.bundle">
                <b v-for="item in product.items" :key="item.id" class="d-block">
                  {{ item.product }} &times;{{ item.count }}
                </b>
              </p>
            </div>

            <button class="btn btn-success my-3" @click.prevent="addToCart(product)">Purchase {{ product.title }} </button>

            <br>
            <template class="my-2" v-if="cartCount">
              <span class='badge'>{{ cartCount }} item(s) in your cart.</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  computed: {
    isCartUpdating() {
      return Styxie.Cart.isLoading
    },
    cartCount() {
      return Styxie.Cart.cart.items.reduce((memo, { product, count }) => {
        if (this.product.id === product.id) memo += count
        return memo
      }, 0)
    }
  },
  methods: {
    addToCart(product) {
      Styxie.Cart.addItem(product)
    },
    removeFromCart(product) {
      Styxie.Cart.decrementOrRemoveItem(product)
    }
  }
}
</script>
