export default [
  {
    is: 'FormInput',
    key: 'businessName',
    attrs: {
      id: 'business_name',
      name: 'client[business_name]',
      label: 'Business name',
      placeholder: 'John\'s Auto Shop',
      hint: 'The name of the business exactly as you want used during campaigns.'
    }
  },
  {
    is: 'FormInput',
    key: 'ownerName',
    attrs: {
      id: 'owner_name',
      name: 'client[owner_name]',
      label: 'Owner name',
      placeholder: 'John Smith',
      hint: 'The name business owner or POC.'
    }
  },
  {
    is: 'FormInput',
    key: 'email',
    attrs: {
      id: 'email',
      name: 'client[email]',
      label: 'Email',
      type: 'email',
      placeholder: 'john@business.com',
      hint: 'The email of the business/client.'
    }
  },
  {
    is: 'FormInput',
    key: 'yearFounded',
    attrs: {
      id: 'year_founded',
      name: 'client[year_founded]',
      label: 'Year founded',
      type: 'number',
      step: 1,
      placeholder: '2020',
      hint: 'The year that the business began operations.'
    }
  },
  {
    is: 'FormInput',
    key: 'addressLine1',
    attrs: {
      id: 'address_line_1',
      name: 'client[address_line_1]',
      label: 'Address line 1',
      placeholder: '123 Main St.',
      hint: 'The first line of the business\' address.'
    }
  },
  {
    is: 'FormInput',
    key: 'addressLine2',
    attrs: {
      id: 'address_line_2',
      name: 'client[address_line_2]',
      label: 'Ste 332',
      placeholder: '123 Main St.',
      hint: 'The suite # or identifier.'
    }
  },
  {
    is: 'FormInput',
    key: 'country',
    attrs: {
      id: 'country',
      name: 'client[country]',
      label: 'Country',
      placeholder: 'United States',
      hint: 'The country that the business operates in.'
    }
  },
  {
    is: 'FormInput',
    key: 'city',
    attrs: {
      id: 'city',
      name: 'client[city]',
      label: 'City',
      placeholder: 'Los Angeles',
      hint: 'The city the business is located in.'
    }
  },
  {
    is: 'FormInput',
    key: 'state',
    attrs: {
      id: 'state',
      name: 'client[state]',
      label: 'State',
      placeholder: 'California',
      hint: 'The state the business is located in.'
    }
  },
  {
    is: 'FormInput',
    key: 'clientZip',
    attrs: {
      id: 'client_zip',
      name: 'client[client_zip]',
      label: 'Zip',
      placeholder: '90210',
      hint: 'The zip the business is located in.'
    }
  },
  {
    is: 'FormTextarea',
    key: 'description',
    attrs: {
      id: 'description',
      name: 'client[description]',
      label: 'Description',
      placeholder: '300-500 characters of business description',
      hint: 'Please write 300-500 words describing the business and services/products offered.'
    }
  },
  {
    is: 'FormInput',
    key: 'gmbUrl',
    attrs: {
      id: 'gmb_url',
      name: 'client[gmb_url]',
      label: 'Gmb url',
      placeholder: 'https://www.google.com/maps?cid=8167675777476425407',
      hint: 'A Google My Business URL for this business. (can be CID or Share)'
    }
  },
  {
    is: 'FormInput',
    key: 'facebookUrl',
    attrs: {
      id: 'facebook_url',
      name: 'client[facebook_url]',
      label: 'Facebook url',
      placeholder: 'https://facebook.com/businessProfile',
      hint: 'The Facebook business profile URL for this business.'
    }
  },
  {
    is: 'FormInput',
    key: 'twitterUrl',
    attrs: {
      id: 'twitter_url',
      name: 'client[twitter_url]',
      label: 'Twitter url',
      placeholder: 'https://www.twitter.com/BusinessProfile',
      hint: 'The Twitter business profile URL for this business.'
    }
  },
  {
    is: 'FormInput',
    key: 'yelpUrl',
    attrs: {
      id: 'yelp_url',
      name: 'client[yelp_url]',
      label: 'Yelp url',
      placeholder: 'https://www.yelp.com/BusinessProfile',
      hint: 'The Yelp business profile URL for this business.'
    }
  },
  {
    is: 'FormInput',
    key: 'instagramUrl',
    attrs: {
      id: 'instagram_url',
      name: 'client[instagram_url]',
      label: 'Instagram url',
      placeholder: 'https://www.instagram.com/BusinessProfile',
      hint: 'The Instagram business profile URL for this business.'
    }
  },
  {
    is: 'FormInput',
    key: 'youtubeUrl',
    attrs: {
      id: 'youtube_url',
      name: 'client[youtube_url]',
      label: 'Youtube url',
      placeholder: 'https://www.instagram.com/BusinessProfile',
      hint: 'The Youtube business URL for this business.'
    }
  },
  {
    is: 'FormInput',
    key: 'image1url',
    attrs: {
      id: 'image_1_url',
      name: 'client[image_1_url]',
      label: 'Image 1 url',
      placeholder: 'https://www.website.com/business-related-image.png',
      hint: 'A unique image that can be used for the business in citations.'
    }
  },
  {
    is: 'FormInput',
    key: 'image2url',
    attrs: {
      id: 'image_2_url',
      name: 'client[image_2_url]',
      label: 'Image 2 url',
      placeholder: 'https://www.website.com/business-related-image.png',
      hint: 'A unique image that can be used for the business in citations.'
    }
  },
  {
    is: 'FormInput',
    key: 'image3url',
    attrs: {
      id: 'image_3_url',
      name: 'client[image_3_url]',
      label: 'Image 3 url',
      placeholder: 'https://www.website.com/business-related-image.png',
      hint: 'A unique image that can be used for the business in citations.'
    }
  },
  {
    is: 'FormTextarea',
    key: 'paymentTypesAccepted',
    attrs: {
      id: 'payment_types_accepted',
      name: 'client[payment_types_accepted]',
      label: 'Payment types accepted',
      placeholder: 'Enter payment types one line at a time.',
      hint: 'The payment types accepted by the business.'
    }
  },
  {
    is: 'FormInput',
    key: 'primaryGmbCategory',
    attrs: {
      id: 'primary_gmb_category',
      name: 'client[primary_gmb_category]',
      label: 'Primary gmb category',
      placeholder: 'Locksmith',
      hint: 'The primary category for this business on GMB.'
    }
  },
  {
    is: 'FormTextarea',
    key: 'secondaryGmbCategory',
    attrs: {
      id: 'secondary_gmb_category',
      name: 'client[secondary_gmb_category]',
      label: 'Secondary gmb category',
      placeholder: 'Enter secondary GMB categories one line at a time.',
      hint: 'The secondary categories for this business on GMB.'
    }
  }
]
