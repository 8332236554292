<template>
  <div class="form-group form-check">
    <input
      v-if="!value"
      type="hidden"
      :name="name"
      :value="false">
    <input
      type="checkbox"
      :class="['form-check-input', { 'is-invalid': hasErrors }]"
      :id="id"
      :name="name"
      :value="true"
      :checked="value"
      @input="onInput($event.target.checked)">
    <label class="form-check-label" :for="id">{{ label }}</label>
    <small v-if="hasErrors" class="form-text text-danger">{{ errors.join(', ') }}</small>
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import fieldMixin from './field_mixin'

export default {
  mixins: [fieldMixin],
  props: {
    value: {
      required: true,
      default: false,
      validator: (prop) => typeof prop === 'boolean' || prop === null
    }
  }
}
</script>
