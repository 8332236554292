<template>
  <div class="card card-body border-light shadow-sm table-wrapper table-responsive mt-4">
    <div class="row">
      <div class="col-12">
        <a href='/guest_posts/new' class='btn btn-primary btn-sm mb-3'>New Guest Post</a><br>

        <div class="row">
          <div class="col-auto mr-auto">
            <label>Per Page: </label>
            <select type="text" class="form-control mb-3" style="width: auto" v-model="perPage">
              <option v-for="val in perPageOptions" :value="val" :key="val">{{ val }}</option>
            </select>
          </div>

          <div class="col-auto">
            <label>Search by niche, website, author: </label>
            <input type="text" class="form-control mb-3" style="width: auto" v-model="search">
          </div>
        </div>

        <VueSlimTable
          remote-url="/ajax/guest_posts"
          :columns="columns"
          table-class="table"
          :custom-filters="customFilters"
          wrapper-class="col-12"
          per-page-key="per"
          :per-page="perPage"
        >
          <template #cell:checkbox="{ row }">
            <div class="form-check dashboard-check">
              <input class="form-check-input" type="checkbox" value="" :id="`checkbox_${row.id}`">
              <label class="form-check-label" :for="`checkbox_${row.id}`" />
            </div>
          </template>

          <template #cell:id="{ value }">
            <a :href="`/guest_posts/${value}`">{{ value }}</a>
          </template>
        </VueSlimTable>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

const PER_PAGE_KEY = 'guest_posts_per_page'

export default {
  data() {
    return {
      search: '',
      customFilters: {},
      perPage: null
    }
  },
  watch: {
    search(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => { this.customFilters = { search: val } }, 300)
    },
    perPage(val) {
      Cookies.set(PER_PAGE_KEY, val)
    }
  },
  created() {
    this.columns = [
      { title: '', key: 'checkbox' },
      { title: 'ID', key: 'id' },
      { title: 'Website', key: 'website', orderable: true },
      { title: 'URL', key: 'website_url', orderable: true },
      { title: 'Follow/NoFollow', key: 'link_attribute', orderable: true },
      { title: 'name', key: 'name', orderable: true },
      { title: 'niche', key: 'niche', orderable: true },
      { title: 'price', key: 'price', orderable: true },
      { title: 'Price (str)', key: 'price_str', orderable: false },
      { title: 'notes', key: 'notes', orderable: true },
      { title: 'Profile', key: 'profile_url', orderable: true },
      { title: 'Placement Type', key: 'placement_type', orderable: true },
      { title: 'Product Link', key: 'product_link', orderable: true }
    ]

    this.perPageOptions = [50, 100, 200, 500]

    const perPage = parseInt(Cookies.get(PER_PAGE_KEY), 10)
    this.perPage = this.perPageOptions.includes(perPage) ? perPage : this.perPageOptions[0]
  }
}
</script>
