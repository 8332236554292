<template>
  <div class="card card-body border-light shadow-sm">
    <ClipLoader v-if="isLoading" />
    <form v-else action="/" @submit.prevent="onSubmit">
      <fieldset :disabled="isSubmiting">
        <component
          v-for="input in inputs"
          :is="input.is"
          :key="input.key"
          :errors="errors[input.key]"
          v-model="client[input.key]"
          v-bind="input.attrs"
        />
        <FormSubmit :is-submiting="isSubmiting" back-link="/clients" />
      </fieldset>
    </form>
  </div>
</template>

<script>
import {
  FormInput, FormTextarea, FormCheckbox, FormSubmit
} from '../../components/form'

import formInputs from './form/inputs'

export default {
  components: {
    FormInput, FormTextarea, FormCheckbox, FormSubmit
  },
  data() {
    return {
      isLoading: false,
      isSubmiting: false,
      client: {
        businessName: null,
        ownerName: null,
        email: null,
        yearFounded: null,
        addressLine1: null,
        addressLine2: null,
        country: null,
        city: null,
        state: null,
        clientZip: null,
        description: null,
        gmbUrl: null,
        facebookUrl: null,
        twitterUrl: null,
        yelpUrl: null,
        instagramUrl: null,
        youtubeUrl: null,
        image1url: null,
        image2url: null,
        image3url: null,
        paymentTypesAccepted: null,
        primaryGmbCategory: null,
        secondaryGmbCategory: null
      },
      errors: {}
    }
  },
  created() {
    this.inputs = formInputs

    const path = window.location.pathname
    if (!path.includes('new')) {
      this.isLoading = true
      axios.get(`/ajax${path.replace('/edit', '')}`)
        .then((res) => {
          this.isLoading = false
          this.client = res.data
        })
    }
  },
  methods: {
    onSubmit(e) {
      const data = new FormData(e.target)
      this.isSubmiting = true
      axios({
        method: this.client.id ? 'put' : 'post',
        url: `/ajax/clients/${this.client.id || ''}`,
        data: data
      }).then(
        () => {
          window.location.pathname = '/clients'
        },
        (xhr) => {
          const { type, error } = xhr.response.data
          if (type === 'validation') { this.errors = error }
        }
      ).finally(() => { this.isSubmiting = false })
    }
  }
}
</script>
